import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { useRef } from "react";
import { useFormik } from "formik";
import AlertInvalidFormat from "../Alert/AlertInvalidFormat";
import axios from "axios";
import * as Yup from "yup";
import { path } from "../../path";
import FormInput from "../formComponents/FormInput";
import React from "react";
//import { useCookies } from "react-cookie";
import { sendPageView } from "../../analytics/ga";
import { getNotifications } from "../../store/asyncMethods/NotificationMethods";
import { SendHGoogleEvent } from "../../analytics/ga";
// import { link } from "fs";
const Login = () => {
  const { loading } = useSelector((state) => state.AuthReducer);
  const location = useLocation();
  const { push } = useHistory();
  const [InvalidFormatAlert, setInvalidFormatAlert] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [passwordView, setpasswordView] = useState(true);
  const dispatch = useDispatch();
  const [primaryEmail, setPrimaryEmail] = useState("");
  useEffect(() => {
    sendPageView(window.location.pathname);
  }, []);

  const { handleChange, errors, touched, handleBlur, handleSubmit, values } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .required("Username or Email is required")
          .matches(/^(?=.*[a-zA-Z0-9])(?!.*[<>]).{1,}$/gm, {
            message: "Invalid username",
          }),
        password: Yup.string()
          .required("Password is required")
          .matches(/^(?=.*[a-zA-Z0-9])(?!.*[<>]).{1,}$/gm, {
            message: "Invalid password",
          }),
      }),
      onSubmit: async (values) => {
        const config = {
          host: `blaccsckull.com`,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `blaccsckull.com`,
          },
        };
        try {
          dispatch({ type: "SET_LOADER" });
          const response = await axios.post(`${path}/login`, values, config);
          // console.log(response);
          if (Object.keys(response.data).length <= 2) {
            toast.error("Wrong credentials entered", toastOption);
            setTimeout(() => {
              setInvalidFormatAlert("");
            }, 3000);
          } else {
            dispatch({ type: "SET_HEADER" });
            localStorage.setItem("myToken", response.data.token);
            const date = new Date();
            // console.log("Date:", date.getMinutes());

            // console.log("------------------------------------------------------------------------------")
            // console.log(response.data.user._id + 'HELLLLLLLLLLLLLLOOOOO')
            // console.log("------------------------------------------------------------------------------")
            dispatch({
              type: "SET_USER",
              token: response.data.token,
              user: response.data.user,
              loginTime: date.getMinutes(),
            });
            SendHGoogleEvent("login", "User login Successfull");
          }
          dispatch(getNotifications(response.data.user._id));
          dispatch({ type: "SET_HEADER" });
          dispatch({ type: "CLOSE_LOADER" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "CLOSE_LOADER" });
        }
      },
    });

  const toastOption = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 2000,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  };

  // password view toggle
  const togglePasswordView = (t) => {
    t.preventDefault();
    let e = ref.current;
    if (e.type === "text") {
      // e.type = "password";
      setPasswordType("password");
      setpasswordView(true);
    } else {
      // e.type = "text";
      setPasswordType("text");
      setpasswordView(false);
    }
  };

  useEffect(() => {
    values.email = location.state?.email;
    setPrimaryEmail(location.state?.email);
  }, [location.state]);

  const handleForgotPassword = (e) => {
    e.preventDefault();
    push("/forgotPassword");
  };

  function useKey(key, cb) {
    const callbackRef = useRef(cb);

    useEffect(() => {
      callbackRef.current = cb;
    });

    useEffect(() => {
      function handle(event) {
        if (event.code === key) {
          callbackRef.current(event);
        }
      }
      document.addEventListener("keypress", handle);
      return () => {
        document.removeEventListener("keypress", handle);
      };
    }, [key]);
  }

  useKey("Enter", handleSubmit);

  const ref = React.createRef();

  return (
    <div className="">
      <Helmet>
        <title>FeedAnts Login</title>
        <meta name="description" content="Login Page" />
      </Helmet>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ style: { marginTop: "50px" } }}
      />
      {InvalidFormatAlert.length > 0 && (
        <AlertInvalidFormat
          msg={InvalidFormatAlert}
          toggle={setInvalidFormatAlert}
        />
      )}
      <div className="p-3">
        <h1 className="text-base font-semibold text-primary font-Open Sans md:text-2xl md:font-bold">
          Login
        </h1>
        <h3 className="text-base font-Open Sans normal text-secondary">
          {" "}
          Welcome back
        </h3>
        <form
          className="flex flex-col justify-center my-3 space-y-5 md:w-[30vw] text-secondary xl:w-[25vw] bg-page"
          data-testid="form">
          <FormInput
            className="text-primary placeholder:text-secondary"
            height="50px"
            fontSize="0.875rem"
            placeholder="Email or Username"
            name="email"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.email || primaryEmail}
            error={errors?.email}
            touched={touched?.email}
          />
          <FormInput
            className="text-primary placeholder:text-secondary"
            height="50px"
            fontSize="0.875rem"
            placeholder="Password"
            name="password"
            type={passwordType}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.password}
            error={errors?.password}
            touched={touched?.password}
            onButtonClick={togglePasswordView}
            passwordView={passwordView}
            iconClassname={"text-[#008080]"}
            ref={ref}
          />
        </form>
        <div className="flex flex-col">
          <button
            className="mt-1 text-sm font-semibold text-left text-secondary md:text-base hover:text-primary w-fit"
            data-testid="forgot-pwd"
            onClick={handleForgotPassword}>
            Forgot Password?
          </button>
          <button
            type="button"
            className="border-solid bg-[#008080] transition duration-200 hover:bg-[#006969] text-lg w-[100%] h-[3rem] rounded-xl  md:h-[3.5rem] text-white mt-2  lg:p-1 lg:text-2xl lg:mt-1"
            data-testid="login"
            onClick={handleSubmit}>
            {loading ? <>...</> : <>Login</>}
          </button>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default React.memo(Login);
