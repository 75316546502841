import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export default function LogoutModal({ showLogoutModal, setShowLogoutModal, logoutSocketEvent }) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const logout = (e) => {
    logoutSocketEvent();
    setShowLogoutModal(false); 
    e.preventDefault();
    // localStorage.removeItem("myToken");
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("persist:root");
    localStorage.clear();
    dispatch({type:"RESET_NOTIFICATION"})
    dispatch({ type: "LOGOUT_SR" });
    dispatch({ type: "UNSET_HEADER" });
    dispatch({ type: "RESET_POSTS" });
    // setAnchorEl(null);
    push("/");
     window.location.reload();
  };
  return (
    <>
      {showLogoutModal ? (
        <>
          <div className=" text-primary justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="top-0 left-0 right-0 bg-card text-primary relative w-auto my-6 mx-auto max-w-3xl rounded-xl border-2 border-viewMore">
              {/*content*/}
              <div className="rounded-lg shadow-lg relative flex flex-col w-full justify-center items-center">
                {/*header*/}
                <div className="items-start justify-between p-2 rounded-t">
                  <p className="text-2xl text-primary font-normal font-sans">
                    Confirm Logout
                  </p>
                </div>
                <hr className="border-viewMore border w-[100%]" />

                <div className="relative px-3 pb-2 pt-0 flex-auto">
                  <p className="my-1 text-secondary text-lg leading-relaxed">
                    Are you sure you want to logout?
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center px-2 pb-4 pt-0 w-[100%]">
                  <button
                    className="text-red-500 w-[30%] bg-red-100 font-bold uppercase p-1 text-sm outline-none rounded focus:outline-none ease-linear transition-all duration-150 mr-2 hover:bg-red-300"
                    type="button"
                    data-testid="closebtn"
                    onClick={() => setShowLogoutModal(false)}>
                    Close
                  </button>
                  <button
                    className="bg-teal-400 hover:bg-teal-500  w-[30%] text-black active:bg-teal-600 font-bold uppercase text-sm p-1 ml-2 rounded shadow hover:shadow-lg outline-none focus:outline-noneease-linear transition-all duration-150"
                    type="button"
                    data-testid="logoutbtn"
                    onClick={logout}>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
