import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
const PrivateRoutes = (props) => {
  const { user } = useSelector((state) => state.AuthReducer);

  return user ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/" from="/polished" />
  );
};
PrivateRoutes.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.func,
};

export default PrivateRoutes;
